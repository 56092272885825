import { API, errorHandling, generateQueryParams, excelImporter } from "../index";
import { setDataInList } from "../../../utils";
import { body, paramInterfaces } from "../interfases/apiInterfaces";
import { DefaultResponse } from "../SSOClient";
import { AccountById, AccountByIdDB, ConfigurationAccount, CreateAccount, OrderConfiguration, ProductServiceConfiguration, SalePointsByCertificate, DeletedAccount } from "./account.types";

const BASE_URL: string = "/api/account";

export async function accountDataList(
  props: {
    search?: string,
    orderBy?: string,
    page?: number,
    pageSize?: number
  }
): Promise<DefaultResponse<any>> {
  return API.get(BASE_URL + generateQueryParams(props))
    .then(({ data }) => {
      return {
        result: true,
        data,
      };
    })
    .catch((err: Error) => {
      return errorHandling(err);
    });
}

export async function getAccountsTypes() {
  let route: string = "-type/select-list";

  return API.get(BASE_URL + route).then((res) => {
    const data = res.data;

    let params: paramInterfaces = {
      key: "id",
      value: "id",
      text: "description",
    };

    return setDataInList(data, params);
  });
}

export async function saveAccount(body: CreateAccount) {
  return API.post(BASE_URL, body).then((res) => {
    let data = { response: res.data, result: true };
    return data;
  });
}

export async function editAccount(body: CreateAccount, id: number): Promise<DefaultResponse<any>> {
  return API.put(BASE_URL + "/" + id, body,).then(({ data }) => {
    return { data, result: true };
  });
}

export async function getAccountById(id: number): Promise<DefaultResponse<AccountByIdDB>> {
  return API.get(BASE_URL + "/" + id).then(({ data }) => {
    return { result: true, data };
  }).catch(errorHandling);
}

export async function unsubscribeAccountById(id: number, values: {
  motivoBajaId?: number;
  fechaBaja?: string;
}) {
  return API.put(BASE_URL + "/" + id + "/desactivate/" + generateQueryParams(values)).then(
    (res) => {
      const data = {
        response: res.data,
        result: res.status === 200 ? true : false,
      };
      return data;
    }
  );
}

export async function getUnsubscribedAccount(): Promise<DefaultResponse<DeletedAccount[]>> {
  const route: string = "/desactivateds";
  return API.get(BASE_URL + route).then(({ data }) => {
    return { result: true, data };
  }
  ).catch(errorHandling);
}

export async function subscribeAccount(id: number) {
  let route: string = "/activate";
  return API.put(BASE_URL + "/" + id + route).then(
    (res) => {
      const data = {
        response: res.data,
        result: res.status === 200 ? true : false,
      };
      return data;
    }
  );
}

export async function getCategories() {
  return API.get("/api/categories").then((res) => {
    const data = {
      result: res.data,
      response: res.status === 200 ? true : false,
    };

    return data;
  });
}

export async function getAccountSettings(id: number): Promise<DefaultResponse<ConfigurationAccount>> {
  return API.get(BASE_URL + "/" + id + "/configuration").then(({ data }) => {
    return {
      result: true,
      data: data,
    };
  }).catch(errorHandling);
}

export async function saveAccountSettings(body): Promise<DefaultResponse<string>> {
  return API.put(BASE_URL + "/configuration", body).then((res) => {
    return {
      result: true,
      data: res["data"],
    };
  }).catch(errorHandling);
}

export async function getOrderConfiguration(id: number): Promise<DefaultResponse<OrderConfiguration>> {
  return API.get(BASE_URL + "/" + id + "/order-configuration").then(({ data }) => {
    return {
      result: true,
      data: data
    };
  });
}

export async function getProductServiceConfiguration(id: number): Promise<DefaultResponse<ProductServiceConfiguration>> {
  return API.get(BASE_URL + "/" + id + "/product-service-configuration").then(
    ({ data }) => {
      return {
        result: true,
        data: data,
      };
    }
  );
}

export async function getSalePointsByCertificate(accountCertificateId: number): Promise<DefaultResponse<SalePointsByCertificate>> {
  return API.get(BASE_URL + "/" + accountCertificateId + "/sale-points").then(
    ({ data }) => {
      return {
        result: true,
        data: data,
      };
    }
  );
}

export const getExcelList = async (params?: string): Promise<DefaultResponse<any>> => {
   return excelImporter
     .get(BASE_URL + "/export-acount-stats-excel-file?search=" + params)
     .then(({ data }) => ({ result: true, data }))
     .catch(errorHandling);
 };