import { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Button from "@mui/material/Button";
import { setIsLoading, toggleMessage } from "../../../../utils/events";
import CCustomSelectOrSearch from "../../../../common/CCustomSelectOrSearch";
import * as accountService from "../../../../api/services/Account";
import { ObtainDisorderedBalances } from "../../../../api/services/methods";
import DisorderedBalancesTable from "./DisorderedBalancesTable";
import { PersonDisorderedBalances } from "../../../../api/services/methods/methods.types";

type IList = {
    text: string;
    value: number;
};


export default function DisorderedBalances() {
    const [disorderedBalancesValue, setDisorderedBalancesValue] = useState<number>(0);
    const [list, setList] = useState<IList[]>([]);
    const [propsTable, setPropsTable] = useState<PersonDisorderedBalances[]>([]);

    useEffect(() => {
        getAccountList();
    }, []);

    const getAccountList = async () => {
        let list: IList[] = [];
        try {
            let { data, message } = await accountService.accountDataList({ orderBy: "+Name" });
            if (data) {
                data["results"].map((item: any) => {
                    list.push({ text: item["name"], value: item["id"] });
                });
            } else {
                toggleMessage("error", message);
            }
        } catch {
            toggleMessage(
                "error",
                "Ocurrió un error inesperado, por favor, inténtelo más tarde"
            );
        }
        setList(list);
    };

    const getObtainDisorderBalance = async (accountId: number) => {
        setIsLoading(true);
        if (accountId > 0 && accountId !== null) {
            let { result, data, message } = await ObtainDisorderedBalances(accountId);
            if (result && data) {
                if (data.length == 0) {
                    toggleMessage("error", "No hay Saldos Desacomodados para esa cuenta");
                    setPropsTable([]);
                } else {
                    setPropsTable(data);
                }
            } else {
                toggleMessage("error", message);
            }
        } else {
            toggleMessage("error", "Debe seleccionar una cuenta");
        }
        setIsLoading(false);
    };

    return (
        <>
            {propsTable.length === 0 ? (
                <div className="c-mt-40 c-ml-100">
                    <Grid container direction="column" alignItems="flex-start" style={{ marginTop: "4%" }} >
                        <h2>Saldos Desacomodados</h2>
                        <div style={{ display: "flex", alignItems: "center", width: "50%" }}>
                            <CCustomSelectOrSearch
                                disabled={false}
                                name="disorderedBalances"
                                placeHolder="Cuenta"
                                defaultValue={disorderedBalancesValue}
                                onChange={(e) => {
                                    setDisorderedBalancesValue(e ? e.value : null);
                                }}
                                value={list.find(
                                    (x: any) => x.value === disorderedBalancesValue
                                )}
                                dataList={list}
                            />
                        </div>

                        <Button variant="contained" size="small" className="c-btn-green c-cursor-pointer c-mt-20 c-ml-5"
                            onClick={() => {
                                getObtainDisorderBalance(disorderedBalancesValue);
                            }}
                        >
                            Ejecutar Proceso
                        </Button>
                    </Grid>
                </div>
            ) : (
                <div className="c-mt-40 c-ml-100">
                    <Button variant="contained" size="small" className="c-btn-red c-cursor-pointer c-mt-20 c-ml-5" style={{ float: "right" }}
                        onClick={() => {
                            setPropsTable([]);
                            setDisorderedBalancesValue(0);
                        }}
                    >
                        Volver
                    </Button>
                    <DisorderedBalancesTable propsTable={propsTable} fetchData={() => getObtainDisorderBalance(disorderedBalancesValue)} />
                </div>
            )}
        </>
    );
}