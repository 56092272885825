import {
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField,
} from "@material-ui/core";
import { PersonDisorderedBalances } from "../../../../api/services/methods/methods.types";
import Button from "@mui/material/Button";
import SyncIcon from '@mui/icons-material/Sync';
import SearchIcon from "@material-ui/icons/Search";
import CustomTooltip from "../../../../common/CTooltip";
import { toggleMessage, setIsLoading } from "../../../../utils/events";
import { useEffect, useState } from "react";
import { UpdateBalance } from "../../../../api/services/methods";
import { formatNumberWithThousandsSeparator } from "../../../../utils";

export default function DisorderedBalancesTable({ propsTable, fetchData }: { propsTable: PersonDisorderedBalances[], fetchData: () => void }) {
    const [valueSearch, setValueSearch] = useState("");
    const [dataTable, setDataTable] = useState<PersonDisorderedBalances[]>([]);

    useEffect(() => {
        setIsLoading(true);
        setDataTable(propsTable);
        setIsLoading(false);
    }, [propsTable]);

    const handleChangeValueSearch = (e: { target: { value: string } }) => {
        setValueSearch(e.target.value.replace(/[^A-Za-z0-9áéíóúüÜÁÉÍÓÚñÑ .-]/g, ""));
    };

    const onClickSearch = () => {
        if (valueSearch != "") {
            setDataTable(propsTable.filter((item: PersonDisorderedBalances) => {
                return item.Persona.toLowerCase().includes(valueSearch.toLowerCase()) || item.Cuenta.toLowerCase().includes(valueSearch.toLowerCase());
            }));
        } else {
            setDataTable(propsTable);
        }
    };

    const updateDisorderedBalance = async (personaId: number, cuentaId: number) => {
        setIsLoading(true);
        UpdateBalance(personaId, cuentaId).then((res) => {
            if (res["result"]) {
                toggleMessage("success", "Saldo Actualizado Correctamente.");
            } else {
                toggleMessage(
                    "error",
                    res["message"] ?? "Ocurrió un error al intentar actualizar los saldos desacomodados."
                );
            }
        }).finally(() => {
            fetchData();
            setIsLoading(false);
        });
    };

    return (
        <>
            <Button variant="contained" size="small" className="c-btn-green c-cursor-pointer c-mt-20 c-ml-5"
                onClick={() => {
                    updateDisorderedBalance(0, dataTable[0]?.CuentaId);
                }}
            >
                <SyncIcon style={{ marginRight: "3px", fontSize: "17px" }} /> Actualizar Saldos
            </Button>
            <h2>Saldos Desacomodados</h2>
            <Grid container spacing={1} justifyContent="center" style={{ marginTop: "-35px" }}>
                <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                    <TextField
                        size="small"
                        id="filled-search"
                        variant="outlined"
                        className="c-mt-10 c-text-center"
                        value={valueSearch}
                        onChange={handleChangeValueSearch}
                    />
                    <Button
                        variant="contained"
                        size="small"
                        className="c-mt-10 c-ml-10 c-cursor-pointer"
                        startIcon={<SearchIcon />}
                        onClick={() => {
                            onClickSearch();
                        }}
                    >
                        Buscar
                    </Button>
                </div>
                <Grid item xs={12}>
                    <TableContainer>
                        <Table className="c-table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="c-text-center">Cuenta</TableCell>
                                    <TableCell className="c-text-center">Cliente/Proveedor</TableCell>
                                    <TableCell className="c-text-center">Saldo Guardado</TableCell>
                                    <TableCell className="c-text-center">Saldo N Guardado</TableCell>
                                    <TableCell className="c-text-center">Saldo Correcto</TableCell>
                                    <TableCell className="c-text-center">Saldo N Correcto</TableCell>
                                    <TableCell className="c-text-center ">Opciones</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataTable.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={7} className="c-text-center">
                                            No hay resultados
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    dataTable.map((item: PersonDisorderedBalances) => (
                                        <TableRow key={item.PersonaId}>
                                            <TableCell className="c-rows-elipsis">{item.Cuenta} - ({item.CuentaId})</TableCell>
                                            <TableCell className="c-rows-elipsis">{item.Persona}</TableCell>
                                            <TableCell className="c-mwp-10">{formatNumberWithThousandsSeparator(item.SaldoGuardado)}</TableCell>
                                            <TableCell className="c-mwp-10">{formatNumberWithThousandsSeparator(item.SaldoNGuardado)}</TableCell>
                                            <TableCell className="c-mwp-10">{formatNumberWithThousandsSeparator(item.SaldoResumen)}</TableCell>
                                            <TableCell className="c-mwp-10">{formatNumberWithThousandsSeparator(item.SaldoNResumen)}</TableCell>
                                            <TableCell>
                                                <CustomTooltip
                                                    title={
                                                        <span className="c-fs-11 ">
                                                            Actualizar Saldos
                                                        </span>
                                                    }
                                                >
                                                    <SyncIcon style={{ marginRight: "3px", fontSize: "17px", color: "green" }}
                                                        onClick={() => {
                                                            updateDisorderedBalance(item.PersonaId, item.CuentaId);
                                                        }}
                                                    />
                                                </CustomTooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </>
    );
}