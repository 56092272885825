import { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Button from "@mui/material/Button";
import { setIsLoading, toggleMessage } from "../../../../utils/events";
import CCustomSelectOrSearch from "../../../../common/CCustomSelectOrSearch";
import * as accountService from "../../../../api/services/Account";
import HelpIcon from '@mui/icons-material/Help';
import CustomTooltip from "../../../../common/CTooltip";
import { CorrectProductsNotRelatedToListPrice } from "../../../../api/services/methods";

type IList = {
    text: string;
    value: number;
};


export default function CorrectProductsRelationship() {
    const [correctProductsRelationshipValue, setCorrectProductsRelationshipValue] = useState<number>(0);
    const [list, setList] = useState<IList[]>([]);

    useEffect(() => {
        getAccountList();
    }, []);

    const getAccountList = async () => {
        let list: IList[] = [];
        try {
            let { data, message } = await accountService.accountDataList({ orderBy: "+ID" });
            if (data) {
                data["results"].map((item: any) => {
                    list.push({ text: item["name"], value: item["id"] });
                });
            } else {
                toggleMessage("error", message);
            }
        } catch {
            toggleMessage(
                "error",
                "Ocurrió un error inesperado, por favor, inténtelo más tarde"
            );
        }
        setList(list);
    };

    const correctProductsNotRelatedToListPrice = async (accountId: number) => {
        setIsLoading(true);
        if (accountId > 0 && accountId !== null) {
            try {
                let { data } = await CorrectProductsNotRelatedToListPrice(accountId);
                if (data != null) {
                    toggleMessage("success", data);
                }
            } catch (error) {
                toggleMessage("error", error);
            }
        } else {
            toggleMessage("error", "Debe seleccionar una cuenta");
        }
        setIsLoading(false);
    };

    return (
        <div className="c-mt-40 c-ml-100">
            <Grid container direction="column" alignItems="flex-start" style={{ marginTop: "4%" }} >
                <h2>Corregir Relación Producto/Lista De Precios</h2>
                <div style={{ display: "flex", alignItems: "center", width: "50%", gap: "10px" }}>
                    <CCustomSelectOrSearch
                        disabled={false}
                        name="AssignPhotosMassively"
                        placeHolder="Cuenta"
                        defaultValue={correctProductsRelationshipValue}
                        onChange={(e) => {
                            setCorrectProductsRelationshipValue(e ? e.value : null);
                        }}
                        value={list.find(
                            (x: any) => x.value === correctProductsRelationshipValue
                        )}
                        dataList={list}
                    />
                    <CustomTooltip title={
                        <span className="c-fs-11 ">
                            Recorre todos los productos existentes y valida relacion con listas de precios
                        </span>
                    }>
                        <HelpIcon style={{ color: "blue" }} />
                    </CustomTooltip>
                </div>
                <Button variant="contained" size="small" className="c-btn-green c-cursor-pointer c-mt-20 c-ml-5"
                    onClick={() => {
                        correctProductsNotRelatedToListPrice(correctProductsRelationshipValue);
                    }}
                >
                    Ejecutar Proceso
                </Button>
            </Grid>
        </div>
    );
}