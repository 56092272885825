import * as accountService from "../../../../api/services/Account";
import * as salesService from "../../../../api/services/sales";
import * as categoriesService from "../../../../api/services/categories";
import { toggleMessage } from "../../../../utils/events";

export async function getConfig(id: number) {
    let { result, data, message } = await accountService.getAccountSettings(id);
    if (result && data) {
        return {
            retentionAgent: data.retentionAgent,
            ecommerce: data.ecommerce,
            ecommerceUrl: data.ecommerceUrl,
            ivaWebOrders: data.ivaWebOrders,
            profitsPercentageWebOrders: data.profitsPercentageWebOrders,
            tiendaNube: data.tiendaNube,
            wooCommerce: data.wooCommerce,
            wooCommerceURL: data.wooCommerceURL,
            productImagesFTP: data.productsImagesFTP,
            fiscalBonus: data.fiscalBonus,
            exportBill: data.exportBill,
            billTypeId: data.billTypeId,
            freightInsurance: data.freightInsurance,
            declaredValueInsurance: data.declareValueInsurance,
            system: data.system,
            ivaBudgetSalePrice: data.ivaPriceBudgetSale,
            ivaBudgetPurchasePrice: data.ivaPriceBudgetPurchase,
            budgetData: data.budgetData,
            remitReportPrintData: data.printDataInRemits,
            barCodeReaderDefault: data.barCodeReaderByDefault,
            confirmCommandVoucher: data.voucherConfirmCommand,
            automaticApplyPositiveBalance: data.positiveBalanceApply,
            confirmBudgetDefault: data.confirmBudgetByDefault,
            saleAlertWhioutStock: data.salesAlertWhitoutStock,
            allowBudgetWhitoutStock: data.allowCheckinBudgetWhitOutStock,
            seeBonus: data.seeBonus,
            allowUpdatePriceForOrderToBill: data.updateOrderPriceToBillOrBudget,
            allowUpdatePriceActiveStatusOrder: data.updateOrderPriceStatusActive,
            allowPrintPricesOrder: data.printPricesOrder,
            allowSendProductsInSales: data.productsActivateInSales,
            seeCostInPriceQuery: data.seeCostInPriceConsult,
            expirationCustomerDefault: data.expirationCustomerDefault,
            expirationProviderDefault: data.expirationProviderDefault,
            salePointFiscalBonus: data.fiscalBonusSalePoint,
            salePointExporter: data.salePointExport,
            voucherCombine: data.voucherCombine,
            dontUpdatePurchaseCost: data.dontUpdatePurchasesCost,
            quantityPerPackage: data.quantityPerPackage,
            multiplier: data.multiplier,
            payConditionId: data.payConditionId,
            printDataInReportRemitBudget: data.printDataInRemitsBudgets,
            discountManuallyStock: data.discountStockManually,
            seeStockByOrder: data.orderQuantity,
            perceptionAgentIIBB: data.iibbPerception,
            saleConfigurationDateAplication: data.saleConfigurationAplicationDate,
            barCodeFormatId: data.barCodeFormat,
            comex: data.comex,
            coinDefaultId: data.coinIdDefault,
            multipleCoins: data.multiplesCurrency,
            multipleCoinsBudget: data.multiplesCurrencyBudget,
            voucherReportLegend: data.voucherReportLegend,
            useServiceOrder: data.useServiceOrder,
            iibbCalculationCustomerAliquot: data.iibbPerceptionCustomerAliquotCalculation,
            productActivateInPriceList: data.productsPriceListActivate,
            searchProductBySupplierCode: data.searchProductBySupplierCode,
            dolarBlue: data.dolarBlue,
            dashboard: data.dashboard,
            mandatoryConceptForPurchase: data.mandatoryConceptForPurchase,
            mandatoryConceptForSale: data.mandatoryConceptForSale,
            hideBalanceSalesReceipts: data.hideBalanceSalesReceipts,
            logoFidelVouchers: data.logoFidelVouchers,
            customerDefaultCity: data.customerDefaultCity,
            portalClient: data.portalClient,
            applyAutoBalanceOnSaleCreditNoteAndReturn: data.applyAutoBalanceOnSaleCreditNoteAndReturn,
            applyAutoBalanceOnSaleInvoiceAndBudget: data.applyAutoBalanceOnSaleInvoiceAndBudget,
            applyAutoBalanceInPurchaseCreditNoteAndReturn: data.applyAutoBalanceInPurchaseCreditNoteAndReturn,
            applyAutoBalanceInPurchaseInvoiceAndBudget: data.applyAutoBalanceInPurchaseInvoiceAndBudget,
            totalSalesReports: data.totalSalesReports,
            transferOrderValueToStock: data.transferOrderValueToStock,
            displayQuantityInApp: data.displayQuantityInApp,
            cuitChargedOnSalesInvoice: data.cuitChargedOnSalesInvoice,
            hideBrandWebOrders: data.hideBrandWebOrders,
            hideStockWebOrders: data.hideStockWebOrders,
            searchPricesWithDiscountsWebOrders: data.searchPricesWithDiscountsWebOrders,
            nameQuantity: data.nameQuantity,
            nameStock: data.nameStock,
            commander: data.commander,
            quantityAlert: data.quantityAlert,
            stockAlert: data.stockAlert,
            quickPrint: data.quickPrint,
            extendedObservationReport: data.extendedObservationReport,
            stockTransferWithStock: data.stockTransferWithStock,
            sendEmailSavingVouchers: data.sendEmailSavingVouchers,
            sendUnpaidTNOrders: data.sendUnpaidTNOrders,
            shippingCostTN: data.shippingCostTN,
            ivaPerception: data.ivaPerception,
            netSubtotalInUnconfirmedBudget: data.netSubtotalInUnconfirmedBudget,
            showSubtotalVATInBudgetNotConfirmed: data.showSubtotalVATInBudgetNotConfirmed,
            qrCodeProducts: data.qrCodeProducts,
            showBalanceInOrder: data.showBalanceInOrder
        }
    } else {
        toggleMessage("error", message)
    }
}

export async function getProductServicesConfiguration(id: number) {
    let { result, data, message } = await accountService.getProductServiceConfiguration(id);
    if (result && data) {
        return {
            allowTotalEdit: data.allowTotalEdit,
            id: data.id,
        }
    } else {
        toggleMessage("error", message)
    }
}

export async function getOrderConfiguration(id: number) {
    let { result, data, message } = await accountService.getOrderConfiguration(id);
    if (result && data) {
        return {
            sendAutomaticCustomerOrderEmail: data.automaticSendEmailCustomer,
            sendAutomaticAccountOrderEmail: data.automaticSendEmailAccount
        }
    } else {
        toggleMessage("error", message)
    }
}

export async function getSalesConfigurations(id: number) {
    let { result, data, message } = await salesService.getSalesConfigurations(id);
    if (result && data) {
        return {
            salesConfiguration: data
        }
    } else {
        toggleMessage("error", message)
    }
}

export async function getCategories(id: number) {   
    let { result, data, message } = await categoriesService.getCategories(id);
    if (result && data) {
        let selectedSalesCategories: number[] = [0];
        let selectedPurchaseCategories: number[] = [0];

        if (data.some((item) => !item.ivaPriceBudgetSale)) {
            data.map((item) => {
                if (item.ivaPriceBudgetSale) {
                    selectedSalesCategories.push(item.id);
                }
            });
        }

        if (data.some((item) => !item.ivaPriceBudgetPurchase)) {
            data.map((item) => {
                if (item.ivaPriceBudgetPurchase) {
                    selectedPurchaseCategories.push(item.id);
                }
            });
        }
        return {
            selectedSalesCategories, selectedPurchaseCategories
        }
    } else {
        toggleMessage("error", message)
    }
}

export async function getSalesPoints(whitoutAFIPCertificate: boolean, certificateList: {
    id: number;
    whitoutCertificateAFIP: boolean;
    changeCertificate: boolean;
    certificate: File;
    contentLenght: number;
    cUIT: string;
    apiKey: string;
    certificateExpiredDay: string;
    deleted: boolean;
    afipSalePoint: string[];
    businessName: string;
}[]) {    
    let selectedSalePoint: string[] = [];
    if (whitoutAFIPCertificate) {
        return { salePointsList: [{ text: "1", value: '1' }], selectedSalePoint };
    } else {
        let salePointsList: { value: string; text: string }[] = [];

        for (let index = 0; index < certificateList.length; index++) {
            const certificate = certificateList[index];
            if (!certificate.deleted) {
                let { data } = await accountService.getSalePointsByCertificate(certificate.id);
                if (data) {            
                    salePointsList =  salePointsList.concat(data.map((item) => 
                        ({
                            value: `${item.code}-${certificate.id}`,
                            text: `${item.code} (${certificate.businessName})`,
                        })
                    ));
                }
            }
            if(certificate.afipSalePoint.length){
                certificate.afipSalePoint.forEach((point) => {
                    selectedSalePoint = selectedSalePoint.concat(`${point}-${certificate.id}`);
                });
            }
        }
        return { salePointsList, selectedSalePoint };
    }
}