import {
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField,
} from "@material-ui/core";
import { PersonDisorderedStocks } from "../../../../api/services/methods/methods.types";
import Button from "@mui/material/Button";
import SyncIcon from '@mui/icons-material/Sync';
import SearchIcon from "@material-ui/icons/Search";
import CustomTooltip from "../../../../common/CTooltip";
import { toggleMessage, setIsLoading } from "../../../../utils/events";
import { useEffect, useState } from "react";
import { UpdateBalance, UpdateStock } from "../../../../api/services/methods";
import { formatNumberWithThousandsSeparator } from "../../../../utils";

export default function DisorderedStocksTable({ propsTable, fetchData }: { propsTable: PersonDisorderedStocks[], fetchData: () => void }) {
    const [valueSearch, setValueSearch] = useState("");
    const [dataTable, setDataTable] = useState<PersonDisorderedStocks[]>([]);

    useEffect(() => {
        setIsLoading(true);
        setDataTable(propsTable);
        setIsLoading(false);
    }, [propsTable]);

    const handleChangeValueSearch = (e: { target: { value: string } }) => {
        setValueSearch(e.target.value.replace(/[^A-Za-z0-9áéíóúüÜÁÉÍÓÚñÑ .-/-]/g, ""));
    };

    const onClickSearch = () => {
        if (valueSearch != "") {
            setDataTable(propsTable.filter((item: PersonDisorderedStocks) => {
                return item.CuentaNombre.toLowerCase().includes(valueSearch.toLowerCase()) || item.ProductoNombre.toLowerCase().includes(valueSearch.toLowerCase());
            }));
        } else {
            setDataTable(propsTable);
        }
    };

    const updateDisorderedStocks = async (personaId: number, cuentaId: number) => {
        setIsLoading(true);
        UpdateStock(personaId, cuentaId).then((res) => {
            if (res["result"]) {
                toggleMessage("success", "Stocks Actualizado Correctamente.");
            } else {
                toggleMessage(
                    "error",
                    res["message"] ?? "Ocurrió un error al intentar actualizar los stocks desacomodados."
                );
            }
        }).finally(() => {
            fetchData();
            setIsLoading(false);
        });
    };

    return (
        <>
            <Button variant="contained" size="small" className="c-btn-green c-cursor-pointer c-mt-20 c-ml-5"
                onClick={() => {
                    updateDisorderedStocks(0, dataTable[0]?.CuentaId);
                }}
            >
                <SyncIcon style={{ marginRight: "3px", fontSize: "17px" }} /> Actualizar Stocks
            </Button>
            <h2>Stocks Desacomodados</h2>
            <Grid container spacing={1} justifyContent="center" style={{ marginTop: "-35px" }}>
                <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                    <TextField
                        size="small"
                        id="filled-search"
                        variant="outlined"
                        className="c-mt-10 c-text-center"
                        value={valueSearch}
                        onChange={handleChangeValueSearch}
                    />
                    <Button
                        variant="contained"
                        size="small"
                        className="c-mt-10 c-ml-10 c-cursor-pointer"
                        startIcon={<SearchIcon />}
                        onClick={() => {
                            onClickSearch();
                        }}
                    >
                        Buscar
                    </Button>
                </div>
                <Grid item xs={12}>
                    <TableContainer>
                        <Table className="c-table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="c-text-center">Cuenta</TableCell>
                                    <TableCell className="c-text-center" style={{ width: "33%" }}>Producto</TableCell>
                                    <TableCell className="c-text-center">Stock</TableCell>
                                    <TableCell className="c-text-center">Stock N</TableCell>
                                    <TableCell className="c-text-center">Stock Calculado</TableCell>
                                    <TableCell className="c-text-center">Stock Guardado</TableCell>
                                    <TableCell className="c-text-center">Stock Guardado N</TableCell>
                                    <TableCell className="c-text-center">Deposito Id</TableCell>
                                    <TableCell className="c-text-center">Opciones</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataTable.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={7} className="c-text-center">
                                            No hay resultados
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    dataTable.map((item: PersonDisorderedStocks) => (
                                        <TableRow key={item.Id}>
                                            <TableCell className="c-rows-elipsis">{item.CuentaNombre} - ({item.CuentaId})</TableCell>
                                            <TableCell className="c-rows-elipsis" style={{ width: "33%" }}>{item.ProductoNombre}</TableCell>
                                            <TableCell className="c-mwp-10">{formatNumberWithThousandsSeparator(item.Stock)}</TableCell>
                                            <TableCell className="c-mwp-10">{formatNumberWithThousandsSeparator(item.StockN)}</TableCell>                                            
                                            <TableCell className="c-mwp-10">{formatNumberWithThousandsSeparator(item.StockCalculado)}</TableCell>
                                            <TableCell className="c-mwp-10">{formatNumberWithThousandsSeparator(item.StockGuardado)}</TableCell>
                                            <TableCell className="c-mwp-10">{formatNumberWithThousandsSeparator(item.StockGuardadoN)}</TableCell>
                                            <TableCell className="c-mwp-10">{formatNumberWithThousandsSeparator(item.DepositoId)}</TableCell>
                                            <TableCell>
                                                <CustomTooltip
                                                    title={
                                                        <span className="c-fs-11 ">
                                                            Actualizar Stock
                                                        </span>
                                                    }
                                                >
                                                    <SyncIcon style={{ marginRight: "3px", fontSize: "17px", color: "green" }}
                                                        onClick={() => {
                                                            updateDisorderedStocks(item.Id, item.CuentaId);
                                                        }}
                                                    />
                                                </CustomTooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </>
    );
}