import { DatePickerView, KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import "./styles.scss";
import { Grid } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { es } from "date-fns/locale";
import moment from "moment";

const CDatePicker = (props) => {
  const { value, setValue, label, type, disabled } = props;
  const config = () => {
    let obj: {
      views: DatePickerView[];
      format: string;
    } = {
      views: ["date"],
      format: "",
    };
  
    if (type === "year") {
      obj.views = ["year"];
      obj.format = "yyyy";
    } else if (type === "month") {
      obj.views = ["year", "month"];
      obj.format = "MMMM";
    } else if (type === "dayWithMonth") {
      obj.views = ["month", "date"];
      obj.format = "dd/MM/yyyy";
    }
  
    return obj;
  };
  
  const { views, format } = config();

  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={DateFnsUtils} locale={es}>
      <Grid>
      <KeyboardDatePicker
        views={views}
        disableToolbar
        variant="inline"
        format={format}
        margin="normal"
        label={label}
        value={value}
        onChange={setValue}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        disabled={disabled ?? false}
      />
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default CDatePicker;
