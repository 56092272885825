import axios from "axios";

/** Instancia de conexión con la API */
export const API = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept",
    Authorization: "Bearer " + localStorage.getItem("tokenLog"),
    withCredentials: true,
    mode: "no-cors",
  },
});

export const excelImporter = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/vnd.ms-excel",
    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    Authorization: "Bearer " + localStorage.getItem("tokenLog"),
    withCredentials: true,
    mode: "no-cors",
    "content-type": "application/vnd.ms-excel",
  },
  responseType: "blob",
});

export const changeToken = (token: string, data: any) => {
  localStorage.clear();
  API.defaults.headers.common["Authorization"] = "Bearer " + token;
  localStorage.setItem("tokenLog", token);
  localStorage.setItem("dataBytokenLog", JSON.stringify(data));
};

/**
 * Handler para las llamadas a la API en caso de error
 * @param {*} err Error del catch()
 */
export const errorHandling = (err: any) => {
  let response = "";

  if (err.response) {
    if (err.response.data && err.response.data.errors) {

      response = err.response.data.errors[0];
    } else {
      response = "Ocurrió un error.";
    }
  } else if (err.request) {
    response = "Ocurrió un error, no se pudo realizar la acción.";
  } else {
    response = "Ocurrió un error, contáctese con el Administrador.";
  }

  return { result: false, message: response };
};

/**
 * Crear una cadena de QueryParams
 * @param {*} data Parametros
 */
export const generateQueryParams = (data: any) => {
  let params = "";

  for (const key in data) {
    if (data[key]) params += (params ? "&" : "?") + key + "=" + data[key];
  }

  return params;
};