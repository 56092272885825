import swal from "sweetalert";
// import { StockTypeEnum, PaymentMethods, SalesChannelEnum } from "./enums";
import moment from "moment";
import { RoleEnum } from "../enums/index";
import jwt from "jwt-decode";

/**
 * Mostrar alerta tipo modal
 * @param {*} type 'success' | 'error'
 * @param {*} title Titulo
 * @param {*} message Mensaje
 */
export function toggleAlert(type: string, title: string, message: string) {
  swal({
    title: title,
    text: message,
    icon: type,
    buttons: [false],
  });
}

/**
 * Mostrar alerta
 * @param {*} type 'success' | 'error' | 'warning' | 'info'
 * @param {*} message Mensaje
 * @param {*} setDataToast Función para mostrarlo
 */
export function toggleToast(type: string, message: string, setDataToast: any) {
  setDataToast({
    active: true,
    type: type,
    message: message,
  });
}

/**
 * Devuelve un Array para poder utilizarlo en los DropDownList
 * @param {*} data Array
 * @param {*} params [key, value, text]
 * @param {*} isCustom Texto con key + text Ejemplo: "152 - Test"
 */
export function setDataInList(data: any, params: any, isCustom?: any) {
  let list: any = [];
  data.forEach((item: any) => {
    let key = item["key"] ?? item["id"];
    let text = item["value"] ?? item["text"];

    list.push(
      generateItemForSelect(key, text, isCustom, item["value"] ?? item["text"])
    );
  });

  return list;
}

/**
 * Devuelve el texto dentro del Array que enviamos buscandolo por su ID
 * @param {*} data Array
 * @param {*} id ID
 */
export function searchTextInList(data: any, id: number) {
  let response = "";

  let result = data.find((e: any) => e.value === id);
  if (result) {
    response = result.text;
  }

  return response;
}

/**
 * Devuelve un Array teniendo como primer item el ID ingresado
 * @param {*} data Array
 * @param {*} id ID
 */
export function refactorDataInList(data: any, id: number) {
  let firstData = data.find((e: any) => e.id === id);
  if (firstData) {
    data = data.filter((e: any) => e.id !== id);
    data.unshift(firstData);
  }

  return { data };
}

/**
 * Genera un item para los select, para utilizarlo como valor por default en el caso de los editar
 * @param {*} key ID
 * @param {*} text TEXTO
 * @param {*} isCustom Texto con key + text Ejemplo: "152 - Test"
 */
export function generateItemForSelect(key: number, text: string, isCustom: string, value: any) {
  return {
    key: key,
    value: value ?? key,
    text: !isCustom ? text : key + " - " + text,
  };
}

/**
 * Transform Date to DD/MM/YYYY
 * @param {*} date Date
 */
export function refactorDate(date: Date) {
  let today = new Date(date);
  var dd: number | string = today.getDate();
  var mm: number | string = today.getMonth() + 1;
  var yyyy = today.getFullYear().toString();
  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  return dd + "/" + mm + "/" + yyyy;
}

/**
 * Transform Date to yyyy-mm-dd
 * @param {*} date Date
 */
export function formatDate(date: Date) {
  let month = "" + (date.getMonth() + 1);
  let day = "" + date.getDate();
  let year = date.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

/**
 * Transform dd-mm-yyy to yyyy-mm-dd
 * @param {*} date Date
 */
export function formatDateFromString(date: any) {
  var dateParts = date.split("/");
  var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);

  return formatDate(new Date(dateObject.toString()));
}

/**
 * Get Stock Types
 */
// export function getStockTypes() {
//   return [
//     {
//       key: StockTypeEnum.Battery.Id,
//       value: StockTypeEnum.Battery.Id,
//       text: StockTypeEnum.Battery.Description,
//     },
//     {
//       key: StockTypeEnum.WarrantyBattery.Id,
//       value: StockTypeEnum.WarrantyBattery.Id,
//       text: StockTypeEnum.WarrantyBattery.Description,
//     },
//     {
//       key: StockTypeEnum.Scrap.Id,
//       value: StockTypeEnum.Scrap.Id,
//       text: StockTypeEnum.Scrap.Description,
//     },
//   ];
// }

/**
 * Get PaymentMethods
 */
// export function getPaymentMethods() {
//   return [
//     {
//       key: PaymentMethods.Cash.Id,
//       value: PaymentMethods.Cash.Id,
//       text: PaymentMethods.Cash.Description,
//     },
//     {
//       key: PaymentMethods.Card.Id,
//       value: PaymentMethods.Card.Id,
//       text: PaymentMethods.Card.Description,
//     },
//   ];
// }

export function serviceNumberToDate(number: any) {
  let day = number.substring(8, 6);
  let month = number.substring(6, 4);
  let year = number.substring(4, 0);

  return `${day}/${month}/${year}`;
}

// export function getSalesChannels() {
//   return [
//     {
//       key: SalesChannelEnum.Insurance.Id,
//       value: SalesChannelEnum.Insurance.Id,
//       text: SalesChannelEnum.Insurance.Description,
//     },
//     {
//       key: SalesChannelEnum.Web.Id,
//       value: SalesChannelEnum.Web.Id,
//       text: SalesChannelEnum.Web.Description,
//     },
//     {
//       key: SalesChannelEnum.Particular.Id,
//       value: SalesChannelEnum.Particular.Id,
//       text: SalesChannelEnum.Particular.Description,
//     },
//   ];
// }

export function findInArray(array: any, value: any) {
  return array.find((x: any) => x.value === value);
}

export function getCurrentDate() {
  return moment().toDate();
}

export function getDateFormated(date: Date) {
  return moment(date).format("DD/MM/YYYY HH:mm");
}

export function getCurrentDateFormated(date: Date) {
  let response: string;

  if (date) {
    let hour = moment().format("HH:mm");
    response = `${moment(date, "DD/MM/YYYY").format("YYYY-MM-DD")}T${hour}`;
  } else response = moment().format("YYYY-MM-DDTHH:mm");

  return response;
}

export function getToken(): any {
  return jwt(localStorage.getItem("tokenLog") || "");
}

export function checkIsAdmin() {
  return getToken().role === RoleEnum.Admin.Description;
}

export function formatNumberWithThousandsSeparator(value: number): string {
  return value.toLocaleString("es-ES");
}
