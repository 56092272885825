import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Checkbox, FormControlLabel, Grid, Paper, TextField } from "@material-ui/core";
import AccountList from "./accountList";
import * as accountService from "../../../api/services/Account";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router-dom";
import { setIsLoading, toggleMessage } from "../../../utils/events";
import saveAs from "file-saver";

export default function Account() {
  const [valueSearch, setValueSearch] = useState("");
  const [freeAccount, setFreeAccount] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [paginationData, setPaginationData] = useState({
    amount: 0,
    currentPage: 1,
    totalRecords: 0,
  });
  const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const history = useHistory();

  const handleChangeRowsPerPage = (event: {
    target: { value: string | number };
  }) => {
    setRowsPerPage(+event.target.value);
    setPaginationData({ ...paginationData, currentPage: 1 });
    reloadData(1, +event.target.value);
  };

  const changePage = (event, value) => {
    let page = value;

    setPaginationData({
      ...paginationData,
      currentPage: value,
    });
    reloadData(page, rowsPerPage);
  };

  const reloadData = (page: number, pageSize: number) => {
    accountList({ search: valueSearch, orderBy: "+Name", page, pageSize, freeAccount });
  };

  async function accountList(props: {
    search?: string;
    orderBy?: string;
    page: number;
    pageSize: number;
    freeAccount: boolean
  }) {
    await accountService.accountDataList(props).then((res) => {
      if (res["result"]) {
        let currentPage = props.page;
        let count = res["data"]["totalRecordsCount"];
        let amount = res["data"]["pagesCount"];

        setDataTable(res["data"]["results"]);

        setPaginationData({
          currentPage: currentPage,
          amount: amount,
          totalRecords: count,
        });
      } else {
        toggleMessage("error", res["message"]);
      }
    });
  }

  const handleChangeValueSearch = (e: { target: { value: string } }) => {
    setValueSearch(
      e.target.value.replace(/[^A-Za-z0-9áéíóúüÜÁÉÍÓÚñÑ .-]/g, "")
    );
    // accountList({
    //   search: e.target.value.replace(/[^A-Za-z0-9 ]/g, ""),
    //   orderBy: "+Name",
    //   page: 1,
    //   pageSize: rowsPerPage,
    // });
  };

  const onClickSearch = () => {
    accountList({
      search: valueSearch,
      orderBy: "+Name",
      page: 1,
      pageSize: rowsPerPage,
      freeAccount
    });
  };

  const freeAccountCheck = (valueCheck: boolean) => {
    setIsLoading(true)
    setFreeAccount(valueCheck);
    accountList({
      search: valueSearch,
      orderBy: "+Name",
      page: 1,
      pageSize: rowsPerPage,
      freeAccount: valueCheck
    }).then(()=>{
      setIsLoading(false)
    });
  };

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      accountList({ orderBy: "+Name", page: 1, pageSize: rowsPerPage, freeAccount }),
    ]).finally(() => setIsLoading(false));
  }, []);

  const getExcelList = async () => {
    try {
      let { result, data, message } = await accountService.getExcelList(
        valueSearch
      );
      if (result && data) {
        let blob = new Blob([data], {
          type: "application/vnd.ms-excel",
        });
        saveAs(blob, "Estadisticas Cuentas.xlsx");
      } else {
        toggleMessage("error", message);
      }
    } catch {
      toggleMessage(
        "error",
        "Ocurrió un error inesperado, por favor, inténtelo más tarde"
      );
    } finally {
      setIsLoadingExport(false);
      setIsLoading(false);
    }
  };

  const unsubscribeAccountById = (
    id: number,
    values: { motivoBajaId?: number; fechaBaja?: string }
  ) => {
    accountService.unsubscribeAccountById(id, values).then((res) => {
      if (res["result"]) {
        toggleMessage("success", "Cuenta dada de baja exitosamente");
        reloadData(paginationData.currentPage ?? 1, rowsPerPage);
      } else {
        toggleMessage(
          "error",
          res["message"] ??
            "Ocurrió un error al dar de baja la cuenta seleccionada"
        );
      }
    });
  };

  return (
    <div className="c-mt-40 c-ml-100">
      <Grid container justifyContent="flex-start">
        <a href={"/cuenta/crear"}>
          <Button
            variant="contained"
            size="small"
            className="c-btn-fidel-green c-cursor-pointer c-mt-5 c-ml-5"
          >
            Agregar Nuevo
          </Button>
        </a>

        <a href={"/cuenta/DadosDeBaja"}>
          <Button
            variant="contained"
            size="small"
            className="c-btn-cancel  c-cursor-pointer c-mt-5 c-ml-5"
          >
            Dados de baja
          </Button>
        </a>
        {/* <a href={"/cuenta/ListaTags"}>
          <Button
            variant="contained"
            size="small"
            className="c-btn-cancel  c-cursor-pointer c-mt-5 c-ml-5"
          >
            Buscar por Tags
          </Button>
        </a> */}
        <a>
          <Button
            variant="contained"
            size="small"
            className="c-btn-fidel-blue c-cursor-pointer c-mt-5 c-ml-5"
            onClick={() => {
              setIsLoadingExport(true);
              setIsLoading(true);
              getExcelList();
            }}
            disabled={isLoadingExport}
          >
            Estadísticas
          </Button>
        </a>
      </Grid>

      <Grid container justifyContent="flex-start">
        <FormControlLabel
          control={
            <Checkbox
              style={{ color: "#0063cc" }}
              onChange={(e) => {
                freeAccountCheck(e.target.checked);
              }}
              name="freeAccount"
            />
          }
          name="freeAccount"
          label="Cuentas Gratuitas"
          labelPlacement="start"
          className="c-mt-15"
        />
      </Grid>
      <Grid container justifyContent="center">
        <TextField
          size="small"
          id="filled-search"
          label="Nombre/Empresa"
          variant="outlined"
          className="c-mt-10 c-text-center"
          value={valueSearch}
          onChange={handleChangeValueSearch}
        />
        <Button
          variant="contained"
          size="small"
          className="c-mt-10 c-ml-10 c-cursor-pointer"
          startIcon={<SearchIcon />}
          onClick={(e) => {
            onClickSearch();
          }}
        >
          Buscar
        </Button>
      </Grid>
      {dataTable && dataTable.length !== 0 ? (
        <AccountList
          dataTable={dataTable}
          history={history}
          paginationData={paginationData}
          changePage={changePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPage={rowsPerPage}
          unsubscribeAccountById={unsubscribeAccountById}
        />
      ) : (
        <Paper className="c-mt-20" elevation={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} className="c-text-center">
              <h5>No se encontraron resultados.</h5>
            </Grid>
          </Grid>
        </Paper>
      )}
    </div>
  );
}
